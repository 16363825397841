// Redirection MonComposant --> Composant1C (exemple MonProfil --> ProfilUser)


import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { store } from "../Redux/Store.js"
import { getUserConnected } from '../Redux/UserConnectedSlice'




export default function MonComposant(props) {
    const navigate = useNavigate()

    useEffect(() => {
        let userConnected = getUserConnected(store.getState())
        navigate(`/${props.link}/${userConnected['Id']}`)
    }, [navigate])

    return null

}